import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
function SvgComponent(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : 25.487}
      height={height ? height : 16.157}
      viewBox="0 0 25.487 16.157"
    >
      <path
        d="M-1006.9,13.157a5.1,5.1,0,0,1-5.1-5.1,5.1,5.1,0,0,1,5.1-5.1,5.31,5.31,0,0,1,.609.037,4.264,4.264,0,0,1,4.067-3.012,4.183,4.183,0,0,1,1.368.228,5.523,5.523,0,0,1,6.2-3.075,5.524,5.524,0,0,1,4.334,5.4,5.428,5.428,0,0,1-.166,1.329,4.676,4.676,0,0,1,3.958,4.966,4.677,4.677,0,0,1-4.643,4.334Zm-4.252-5.1a4.252,4.252,0,0,0,4.252,4.252h15.73a3.825,3.825,0,0,0,3.84-3.767,3.824,3.824,0,0,0-3.726-3.879.428.428,0,0,1-.339-.185.427.427,0,0,1-.049-.383,4.671,4.671,0,0,0-2.816-5.932A4.672,4.672,0,0,0-1000.225.9a.429.429,0,0,1-.236.245.426.426,0,0,1-.34-.007,3.385,3.385,0,0,0-1.421-.315,3.41,3.41,0,0,0-3.334,2.728.425.425,0,0,1-.5.333,4.335,4.335,0,0,0-.842-.085A4.252,4.252,0,0,0-1011.15,8.055Zm14.656,1.14V8.91l.725-.783a1.194,1.194,0,0,0,.188-.257.488.488,0,0,0,.06-.231.282.282,0,0,0-.086-.222.359.359,0,0,0-.244-.075.971.971,0,0,0-.58.222l-.128-.283a.909.909,0,0,1,.324-.181,1.219,1.219,0,0,1,.4-.067.765.765,0,0,1,.509.157.527.527,0,0,1,.187.424.773.773,0,0,1-.079.346,1.577,1.577,0,0,1-.266.363l-.525.558h.949V9.2ZM-1000.31,8a2.078,2.078,0,0,1-.825-.889,3,3,0,0,1-.292-1.362,3,3,0,0,1,.288-1.355,2.031,2.031,0,0,1,.822-.886,2.494,2.494,0,0,1,1.264-.308,2.494,2.494,0,0,1,1.264.308,2.027,2.027,0,0,1,.82.886,3.055,3.055,0,0,1,.283,1.355,3.034,3.034,0,0,1-.286,1.362,2.072,2.072,0,0,1-.82.889,2.457,2.457,0,0,1-1.26.311A2.462,2.462,0,0,1-1000.31,8Zm.178-3.609a2.1,2.1,0,0,0-.384,1.358,2.13,2.13,0,0,0,.384,1.365,1.312,1.312,0,0,0,1.078.476,1.311,1.311,0,0,0,1.078-.476,2.12,2.12,0,0,0,.385-1.365,2.092,2.092,0,0,0-.385-1.355,1.313,1.313,0,0,0-1.078-.472A1.32,1.32,0,0,0-1000.132,4.391Z"
        transform="translate(1012 3)"
        fill={fill}
        strokeWidth="0.25px"
      />
    </svg>
  );
}

export default SvgComponent;

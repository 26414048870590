import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
function SvgComponent(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : 22.858}
      height={height ? height : 20}
      viewBox="0 0 22.858 20"
    >
      <defs></defs>
      <path
        d="M-1001.745,16.946c-.419-.219-10.255-5.472-10.255-14.2A5.749,5.749,0,0,1-1006.257-3a5.708,5.708,0,0,1,4.734,2.4A5.707,5.707,0,0,1-996.79-3a5.749,5.749,0,0,1,5.743,5.742,10.7,10.7,0,0,1-.16,1.886.477.477,0,0,1-.554.384.476.476,0,0,1-.384-.554A9.617,9.617,0,0,0-992,2.743a4.8,4.8,0,0,0-4.79-4.79A4.707,4.707,0,0,0-1001.1.542a.5.5,0,0,1-.856,0,4.706,4.706,0,0,0-4.306-2.59,4.8,4.8,0,0,0-4.791,4.79c0,7.467,8.074,12.41,9.525,13.237a25.279,25.279,0,0,0,4.989-3.795.476.476,0,0,1,.674,0,.476.476,0,0,1,0,.673,25.318,25.318,0,0,1-5.437,4.084.477.477,0,0,1-.221.054A.481.481,0,0,1-1001.745,16.946Zm1.641-5.66a.474.474,0,0,1-.433-.3l-1.785-4.463h-2.535a.475.475,0,0,1-.476-.476.475.475,0,0,1,.476-.476H-1002a.475.475,0,0,1,.441.3l1.486,3.715,3.351-7.54a.481.481,0,0,1,.412-.284.461.461,0,0,1,.437.24l3.673,6.427h2.581a.477.477,0,0,1,.476.477.477.477,0,0,1-.476.476h-2.858a.476.476,0,0,1-.413-.24l-3.344-5.851L-999.66,11a.476.476,0,0,1-.434.282Z"
        transform="translate(1012 3)"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;

import { Box, Button, Container, TextField, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleHeader from "../../../../components/Header/SimpleHeader";
import theme from "../../../../theme/theme";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { AnsewrOpinion, CancelAnsewrOpinion } from '../../../../store/ducks/opinion/types'
import { createAnswerRequest } from '../../../../store/ducks/opinion/actions'
import { loadOpinionRequest } from '../../../../store/ducks/opinions/actions'
import LOCALSTORAGE from "../../../../helpers/constants/localStorage";
import Loading from "../../../../components/Loading";

interface AnswerOpinionI {
  close: () => void;
  detailsAnswer: any[];
  detailObj?: any;
}

export default function AnswerOpinion(props: AnswerOpinionI) {
  const dispatch = useDispatch();

  const opinionAnswerState = useSelector(
    (state: ApplicationState) => state.opinionAnswer
  );

  const opinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  const validationSchema = yup.object().shape({
    medicalOpinion: yup.string().required("O campo resposta é obrigatório"),
  });

  const validationCancelationSchema = yup.object().shape({
    cancelationJustification: yup.string().required("O campo justificativa é obrigatório"),
  });

  const { handleSubmit, control, getValues, resetField, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      medicalOpinion: "",
    },
  });

  const [status, setStatus] = useState("Aguardando resposta");
  const [canAnswer, setCanAnswer] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const medicalOpinion = getValues("medicalOpinion");

  /* const detailsAnswer = [
    { name: "Paciente", value: "Juninho da TOIC" },
    { name: "Prestador Solicitante", value: "Nanãi de Rio Doce" },
    { name: "Setor Paciente", value: "AIS - ÁREAS COMUNS" },
    { name: "Data da Solicitação", value: "20/07/2023 13:52" },
    { name: "Especialidade", value: "DERMATOLOGIA" },
    { name: "Justificativa", value: "Tá cheio de mancha na pele" },
    { name: "Situação", value: status },
  ]; */
  const opinionanswersSize = props?.detailObj?.opinionanswers?.length

  const external_user_id = localStorage.getItem(LOCALSTORAGE.SOLLAR_INTEGRATION_USER_ID) || "";
  const user_id = localStorage.getItem(LOCALSTORAGE.USER_ID) || "";
  const username = localStorage.getItem(LOCALSTORAGE.USERNAME) || "";

  useEffect(() => {
    if (opinionAnswerState.createdSuccess) {
      dispatch(loadOpinionRequest({id: opinionState.data.external_attendance_id}))
      resetField("medicalOpinion")
      setCanAnswer(false)
      setStatus('')
    }
  }, [opinionAnswerState.createdSuccess])

  return (
    <>
      <Container sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingY: 5 }}>
        {opinionAnswerState.loading && <Loading />}
        <SimpleHeader title="Informações da Solicitação" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
        >
          {props.detailsAnswer.map((item) => (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography fontWeight={550}>{item.name}:</Typography>
              <Typography>{item.value}</Typography>
            </Box>
          ))}
          {opinionanswersSize === 0 && !canAnswer ? (
            <Tooltip
            title={'Você não possui a especialidade do parecer'}
            arrow placement="bottom-start"
            disableHoverListener={opinionState.data.userSpecialities.includes(props.detailObj.external_specialty_name)}
            >
            <span>
            <Button
              onClick={() => {
                setCanAnswer(true);
                setStatus("Respondendo");
                setCanceled(false);
              }}
              variant="contained"
              sx={{ maxWidth: 240, width: "100%", mt: 2 }}
              disabled={!opinionState.data.userSpecialities.includes(props.detailObj.external_specialty_name)}
            >
                Iniciar análise
            </Button>
            </span>
          </Tooltip>
          ) : (
            ""
          )}
        </Box>
      </Container>
      {
        props?.detailObj?.opinionanswers?.map((answers, index) => {
          return (
            <Container
              sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingBottom: 5 }}
            >
              <SimpleHeader title="Resposta do Parecer" canceled={answers?.canceled_by} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.whiteBackgroud.main,
                  color: theme.palette.text.primary,
                  paddingX: 3,
                  paddingY: 3,
                  minHeight: "48px",
                }}
              >
                {true ? (
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Responsável:</Typography>
                      <Typography>{answers.answered_by}</Typography>
                    </Box>
                    {answers?.canceled_by && (
                      <>
                        <Box display={"flex"} gap={1}>
                          <Typography fontWeight={550}>Cancelado em:</Typography>
                          <Typography>{answers?.canceled_at}</Typography>
                        </Box>
                        <Box display={"flex"} gap={1}>
                          <Typography fontWeight={550}>Motivo do Cancelamento:</Typography>
                          <Typography>{answers?.cancel_reason}</Typography>
                        </Box>
                      </>
                    )}
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Especialidade do Responsável:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answered_by_speciality}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Data:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answered_at}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Resposta:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answer}</Typography>
                    </Box>

                    {(answers?.answered_by_id === user_id) && (index + 1) === opinionanswersSize && !answers?.canceled_by && (
                      <>
                        <Button
                          variant="contained"
                          color="error"
                          disabled={canceled}
                          sx={{
                            maxWidth: 180,
                            width: "100%",
                            marginTop: 2,
                          }}
                          onClick={() => {
                            setCanceled(true);
                            setStatus("Cancelado");
                          }}
                        >
                          Cancelar Análise
                        </Button>
                        {canceled && (
                          <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: theme.palette.whiteBackgroud.main,
                            color: theme.palette.text.primary,
                            paddingX: 3,
                            paddingY: 3,
                            minHeight: "48px",
                          }}
                        >
                            <form
                              onSubmit={handleSubmit((obj: any) => {
                                const cancelAnswerObj: CancelAnsewrOpinion = {
                                  answer_id: answers._id,
                                  opinion_id: props?.detailObj?._id,
                                  answered_by: user_id,
                                  external_answered_by: external_user_id,
                                  answer: obj.medicalOpinion
                                }
                                dispatch(createAnswerRequest(cancelAnswerObj))
                              })}
                            >
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={1.5}
                                mb={2.5}
                              >
                                <Controller
                                  control={control}
                                  name="medicalOpinion"
                                  render={({ field: { onChange, value }, fieldState }) => (
                                    <TextField
                                      color="secondary"
                                      size="small"
                                      name="medicalOpinion"
                                      label="Motivo do cancelamento"
                                      multiline
                                      placeholder=""
                                      value={value}
                                      onChange={onChange}
                                      sx={{ width: "100%" }}
                                      error={!!fieldState.error?.message}
                                      helperText={fieldState.error?.message}
                                      minRows={7}
                                    />
                                  )}
                                />
                              </Box>
                              <Button
                                color="error"
                                type="submit"
                                variant="contained"
                                sx={{ maxWidth: 180, width: "100%" }}
                              >
                                Cancelar
                              </Button>
                            </form>
                        </Box>
                        )}
                      </>
                    )}
                  </Box>
                ) : status == "Cancelado" ? (
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550} sx={{textDecoration: 'line-through'}}>Data:</Typography>
                      <Typography sx={{textDecoration: 'line-through'}}>20/07/2023 15:32</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550} sx={{textDecoration: 'line-through'}}>Justificativa:</Typography>
                      <Typography sx={{textDecoration: 'line-through'}}>{medicalOpinion}</Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Container>
          )
        })
      }
      {status == "Respondendo" && (
          <Container
              sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingBottom: 5 }}
            >
              <SimpleHeader title="Responder Parecer" canceled={false} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.whiteBackgroud.main,
                  color: theme.palette.text.primary,
                  paddingX: 3,
                  paddingY: 3,
                  minHeight: "48px",
                }}
              >
                  <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Responsável:</Typography>
                      <Typography>{username}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Especialidade do Responsável:</Typography>
                      <Typography>{opinionState?.data.userSpecialities.includes(props.detailObj.external_specialty_name) ? props.detailObj.external_specialty_name : ''}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1} paddingBottom={2}>
                      <Typography fontWeight={550}>Data:</Typography>
                      <Typography>{opinionState?.data?.now}</Typography>
                    </Box>
                  <form
                    onSubmit={handleSubmit((obj: any) => {
                      const answerObj: AnsewrOpinion = {
                        opinion_id: props?.detailObj?._id,
                        answered_by: user_id,
                        external_answered_by: external_user_id,
                        answer: obj.medicalOpinion
                      }
                      dispatch(createAnswerRequest(answerObj))
                    })}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={1.5}
                      mb={2.5}
                    >
                      <Controller
                        control={control}
                        name="medicalOpinion"
                        render={({ field: { onChange, value }, fieldState }) => (
                          <TextField
                            color="secondary"
                            size="small"
                            name="medicalOpinion"
                            label="Resposta"
                            multiline
                            placeholder="Obs..."
                            value={value}
                            onChange={onChange}
                            sx={{ width: "100%" }}
                            error={!!fieldState.error?.message}
                            helperText={fieldState.error?.message}
                            minRows={7}
                          />
                        )}
                      />
                    </Box>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ maxWidth: 180, width: "100%" }}
                    >
                      Salvar
                    </Button>
                  </form>
              </Box>
            </Container>
        )}


    <Container sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingY: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 0,
            minHeight: "48px",
          }}
        >

          {opinionanswersSize > 0 && props?.detailObj?.status.label === 'PARECER PENDENTE' && status != 'Respondendo'? (
            <Tooltip
            title={'Você não possui a especialidade do parecer'}
            arrow placement="bottom-start"
            disableHoverListener={opinionState.data.userSpecialities.includes(props.detailObj.external_specialty_name)}
            >
            <span>
            <Button
              onClick={() => {
                setCanAnswer(true);
                setStatus("Respondendo");
                setCanceled(false);
              }}
              variant="contained"
              sx={{ maxWidth: 240, width: "100%", mt: 2 }}
              disabled={!opinionState.data.userSpecialities.includes(props.detailObj.external_specialty_name)}
            >
                Iniciar análise
            </Button>
            </span>
          </Tooltip>
          ) : (
            ""
          )}
        </Box>
      </Container>
    </>
  );
}

import { Reducer } from "redux";
import { OpinionState, OpinioTypes } from "./types";

export const INITIAL_STATE: OpinionState = {
  data: {
    name: '',
    external_attendance_id: '',
    sector: '',
    rows: [],
    opinions: []
  },
  list: {
    data: [],
    limit: "10",
    page: "1",
    total: 0,
  },
  loading: false,
  success: false,
};

const reducer: Reducer<OpinionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OpinioTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case OpinioTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: INITIAL_STATE.data,
        list: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinioTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinioTypes.LOAD_DATA_REQUEST:
      return { ...state, loading: true, success: false, createdSuccess: false };
    case OpinioTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinioTypes.LOAD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinioTypes.CREATE_ANSWER_REQUEST:
      return { ...state, loading: true, success: false, createdSuccess: false };
    case OpinioTypes.CREATE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        createdSuccess: true
      };
    case OpinioTypes.CREATE_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinioTypes.CLEAN:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;

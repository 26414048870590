import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function EvolutionIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "35"}
      height={height ? height : "50"}
      viewBox="0 0 35 50"
    >
      <defs></defs>
      <path
        fill={fill ? fill : "#0899ba"}
        d="M2.484,50A2.487,2.487,0,0,1,0,47.516V7.484A2.486,2.486,0,0,1,2.484,5H12.5V4.167A4.172,4.172,0,0,1,16.667,0h1.666A4.172,4.172,0,0,1,22.5,4.167V5H32.516A2.487,2.487,0,0,1,35,7.484V47.516A2.488,2.488,0,0,1,32.516,50ZM1.667,7.484V47.516a.819.819,0,0,0,.817.818H32.516a.819.819,0,0,0,.817-.818V7.484a.819.819,0,0,0-.817-.818H25.75A4.175,4.175,0,0,1,21.667,10H13.333A4.175,4.175,0,0,1,9.25,6.666H2.484A.819.819,0,0,0,1.667,7.484Zm11.667.849h8.333a2.5,2.5,0,0,0,2.349-1.667H10.986A2.5,2.5,0,0,0,13.333,8.333Zm.833-4.166V5h6.666V4.167a2.5,2.5,0,0,0-2.5-2.5H16.667A2.5,2.5,0,0,0,14.167,4.167Zm-10.833,40V42.5H31.667v1.667Zm0-3.334V39.167H31.667v1.666Zm0-3.333V35.833H31.667V37.5Zm0-3.334V32.5H31.667v1.667Zm0-3.334V29.166H31.667v1.667Zm5.833-6.666a.834.834,0,0,1-.766-.5L6.117,18.333H3.334V16.666H6.666a.834.834,0,0,1,.77.5l1.637,3.818,2.64-7.916a.833.833,0,0,1,1.619.172l.561,5.045,1.23-2.049a.833.833,0,0,1,1.515.2l.928,3.243,1.647-4.944a.834.834,0,0,1,1.537-.109L22.181,17.5h9.485v1.667h-10a.832.832,0,0,1-.743-.46l-.767-1.534-1.863,5.591a.846.846,0,0,1-.808.571.835.835,0,0,1-.784-.6l-1.138-3.987-1.513,2.52a.833.833,0,0,1-1.543-.337L12.091,17.2,9.958,23.6a.834.834,0,0,1-.756.57Z"
      />
    </svg>
  );
}

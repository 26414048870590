import React from "react";

interface IPropsSvg {
  fill: any;
  fill2?: any;
  width?: any;
  height?: any;
}

export default function Hospitalization(props: IPropsSvg) {
  const { fill, fill2, width, height } = props;

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_752_11477)">
        <path
          d="M12 24C9.62663 24 7.30655 23.2962 5.33316 21.9776C3.35977 20.6591 1.8217 18.7849 0.913451 16.5922C0.0051994 14.3995 -0.232441 11.9867 0.230582 9.65892C0.693605 7.33115 1.83649 5.19295 3.51472 3.51472C5.19295 1.83649 7.33115 0.693605 9.65892 0.230582C11.9867 -0.232441 14.3995 0.0051994 16.5922 0.913451C18.7849 1.8217 20.6591 3.35977 21.9776 5.33316C23.2962 7.30655 24 9.62663 24 12C23.9969 15.1817 22.7317 18.2321 20.4819 20.4819C18.2321 22.7317 15.1817 23.9969 12 24ZM5.41936 9.32671C5.34592 9.32753 5.27578 9.35739 5.22428 9.40976C5.17278 9.46213 5.14411 9.53275 5.14452 9.6062V14.0717C5.14411 14.1452 5.17278 14.2158 5.22428 14.2682C5.27578 14.3206 5.34592 14.3504 5.41936 14.3512H9.04955C9.12341 14.3516 9.1941 14.3813 9.24618 14.4336C9.29826 14.486 9.32749 14.5569 9.32749 14.6307V18.257C9.32687 18.3306 9.35545 18.4014 9.40697 18.454C9.4585 18.5065 9.52875 18.5365 9.60233 18.5373H14.0694C14.1434 18.5369 14.2142 18.5072 14.2663 18.4546C14.3184 18.4021 14.3476 18.331 14.3474 18.257V14.6323C14.3476 14.5582 14.3771 14.4872 14.4294 14.4349C14.4818 14.3825 14.5528 14.353 14.6268 14.3528H18.2532C18.3272 14.3526 18.3982 14.3231 18.4506 14.2707C18.5029 14.2183 18.5324 14.1474 18.5326 14.0733V9.6062C18.5324 9.53214 18.5029 9.46117 18.4506 9.4088C18.3982 9.35643 18.3272 9.32692 18.2532 9.32671H14.6284C14.5543 9.32651 14.4834 9.297 14.431 9.24463C14.3786 9.19226 14.3491 9.12129 14.3489 9.04723V5.41936C14.3489 5.3455 14.3197 5.27465 14.2676 5.22228C14.2155 5.16991 14.1448 5.14029 14.071 5.13988H9.6C9.5271 5.1415 9.45776 5.17171 9.40692 5.22399C9.35609 5.27627 9.32784 5.34644 9.32826 5.41936V9.04646C9.32826 9.12031 9.29903 9.19117 9.24695 9.24354C9.19487 9.29591 9.12418 9.32553 9.05033 9.32594L5.41936 9.32671Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_752_11477">
          <rect width="24" height="24" fill={fill2 ? fill2 : "white"} />
        </clipPath>
      </defs>
    </svg>
  );
}

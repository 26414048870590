import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function THCStype1(props: IPropsSvg) {
  const { fill, width, height } = props;
  // #273471
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "927.47"}
      height={height ? height : "127.43"}
      viewBox="0 0 927.47 127.43"
    >
      <g
        id="Grupo_11422"
        data-name="Grupo 11422"
        transform="translate(-3762.58 -483.797)"
      >
        <g id="g156" transform="translate(4340.626 486.988)">
          <path
            id="path158"
            d="M6115.507-3131.308h-4.427v-43.074h4.427v18.9h24.1v-18.9h4.428v43.074H6139.6v-20.279h-24.1v20.279"
            transform="translate(-6111.08 3174.383)"
            fill={fill}
          />
        </g>
        <g id="g160" transform="translate(4382.62 497.846)">
          <path
            id="path162"
            d="M6191.952-3124.413a14.044,14.044,0,0,1-7.658-2.071,13.8,13.8,0,0,1-5.074-5.849,20.028,20.028,0,0,1-1.788-8.758,17.935,17.935,0,0,1,1.836-8.251,15.066,15.066,0,0,1,5.1-5.758,12.967,12.967,0,0,1,7.345-2.128,13.7,13.7,0,0,1,5.991,1.288,13.121,13.121,0,0,1,4.566,3.631,15.773,15.773,0,0,1,2.754,5.489,19.76,19.76,0,0,1,.628,6.989h-25.972v-2.88h21.647c-.145-3.561-1.087-6.33-2.8-8.309a8.566,8.566,0,0,0-6.862-2.972,8.974,8.974,0,0,0-5.291,1.592,10.6,10.6,0,0,0-3.575,4.467,16.468,16.468,0,0,0-1.3,6.84,18.342,18.342,0,0,0,1.256,7.11,10.146,10.146,0,0,0,3.552,4.648,9.6,9.6,0,0,0,5.581,1.619,9.8,9.8,0,0,0,6.475-2.131,9.859,9.859,0,0,0,3.334-5.849h4.083a13.8,13.8,0,0,1-4.759,8.251,13.83,13.83,0,0,1-9.061,3.03"
            transform="translate(-6177.432 3157.227)"
            fill={fill}
          />
        </g>
        <g id="g164" transform="translate(4417.8 497.846)">
          <path
            id="path166"
            d="M6236.964-3133.89a5.9,5.9,0,0,0,2.021,4.649,7.662,7.662,0,0,0,5.26,1.77,9.035,9.035,0,0,0,6.892-2.671,9.823,9.823,0,0,0,2.533-7.106v-2.82c-1.315-.122-2.484-.2-3.434-.241-.975-.039-1.9-.058-2.752-.058q-5.26,0-7.891,1.587A5.32,5.32,0,0,0,6236.964-3133.89Zm17.12,8.879v-5.759a10.534,10.534,0,0,1-4.067,4.651,11.683,11.683,0,0,1-6.38,1.707,11.241,11.241,0,0,1-7.72-2.55,8.637,8.637,0,0,1-2.9-6.807,7.818,7.818,0,0,1,3.6-6.992c2.412-1.578,5.966-2.37,10.691-2.37.95,0,1.851.022,2.776.061q1.352.062,3.58.3v-2.7a8.985,8.985,0,0,0-1.973-6.18,6.973,6.973,0,0,0-5.5-2.219,8.166,8.166,0,0,0-6.039,2.25,8.407,8.407,0,0,0-2.289,6.088h-3.775a12.166,12.166,0,0,1,1.583-6.149,10.82,10.82,0,0,1,4.237-4.079,12.643,12.643,0,0,1,6.21-1.471c3.653,0,6.5,1.021,8.5,3.061,2.021,2.038,3.02,4.9,3.02,8.577v20.578h-3.556"
            transform="translate(-6233.019 3157.227)"
            fill={fill}
          />
        </g>
        <g id="g168" transform="translate(4453.159 486.992)">
          <path
            id="path170"
            d="M6293.018-3131.3h-4.131v-43.073h4.131v43.073"
            transform="translate(-6288.887 3174.376)"
            fill={fill}
          />
        </g>
        <g id="g172" transform="translate(4463.443 489.09)">
          <path
            id="path174"
            d="M6326.033-3134.107v3.719a8.923,8.923,0,0,1-2.24.719,13.083,13.083,0,0,1-2.239.18,10.814,10.814,0,0,1-4.6-.958,7.209,7.209,0,0,1-3.3-3.062,11.183,11.183,0,0,1-1.206-5.518v-19.138h-7.31v-3.539h7.31v-9.36h4.135v9.36h9.746v3.539h-9.746v18.239q0,3.718,1.624,5.156a6.068,6.068,0,0,0,4.16,1.442,8.925,8.925,0,0,0,1.87-.182,9.179,9.179,0,0,0,1.8-.6"
            transform="translate(-6305.137 3171.063)"
            fill={fill}
          />
        </g>
        <g id="g176" transform="translate(4492.967 486.988)">
          <path
            id="path178"
            d="M6355.9-3149.725v18.418h-4.119v-43.076h4.119v17.279a12.154,12.154,0,0,1,4.021-4.618,10.215,10.215,0,0,1,5.955-1.8,10.22,10.22,0,0,1,7.768,3.06c1.96,2.04,2.928,4.918,2.928,8.638v20.517h-4.145v-19.077c0-3.037-.645-5.38-1.96-7.02a7.022,7.022,0,0,0-5.831-2.457,8.058,8.058,0,0,0-4.417,1.288,8.911,8.911,0,0,0-3.152,3.568,11.56,11.56,0,0,0-1.166,5.281"
            transform="translate(-6351.784 3174.383)"
            fill={fill}
          />
        </g>
        <g id="g180" transform="translate(4526.377 522.748)">
          <path
            id="path182"
            d="M6413.546-3112.544a8.587,8.587,0,0,1-1.072,4.2,8.432,8.432,0,0,1-3.065,3.243,9.247,9.247,0,0,1-4.835,1.318v-2.52a6.864,6.864,0,0,0,4.162-1.59,4.867,4.867,0,0,0,1.744-3.509,2.573,2.573,0,0,1-.672.239,3.9,3.9,0,0,1-.7.063,3.022,3.022,0,0,1-2.318-.992,3.243,3.243,0,0,1-.922-2.308,3.232,3.232,0,0,1,1.047-2.549,3.565,3.565,0,0,1,2.492-.93,3.557,3.557,0,0,1,3.115,1.468,6.716,6.716,0,0,1,1.022,3.87"
            transform="translate(-6404.574 3117.882)"
            fill={fill}
          />
        </g>
        <g id="g184" transform="translate(4558.079 486.092)">
          <path
            id="path186"
            d="M6475.4-3130.925a19.589,19.589,0,0,1-8.2-1.708,20.08,20.08,0,0,1-6.606-4.771,22.419,22.419,0,0,1-4.354-7.168,24.783,24.783,0,0,1-1.569-8.849,24.537,24.537,0,0,1,1.569-8.819,22.141,22.141,0,0,1,4.354-7.11,20.284,20.284,0,0,1,6.606-4.739,19.589,19.589,0,0,1,8.2-1.708,20.427,20.427,0,0,1,9.264,2.128,19.646,19.646,0,0,1,7.112,6,20.227,20.227,0,0,1,3.7,9.029h-4.658a15.865,15.865,0,0,0-2.835-6.809,15.728,15.728,0,0,0-5.417-4.65,15.216,15.216,0,0,0-7.163-1.678,15.056,15.056,0,0,0-6.48,1.38,15.843,15.843,0,0,0-5.163,3.84,17.485,17.485,0,0,0-3.367,5.819,22.011,22.011,0,0,0-1.164,7.318,22.2,22.2,0,0,0,1.164,7.379,17.8,17.8,0,0,0,3.367,5.849,15.632,15.632,0,0,0,5.163,3.868,15.029,15.029,0,0,0,6.48,1.382,15.291,15.291,0,0,0,7.214-1.711,15.532,15.532,0,0,0,5.392-4.708,16.034,16.034,0,0,0,2.81-6.779h4.658a20.956,20.956,0,0,1-3.646,9,19.681,19.681,0,0,1-7.139,6.06,20.289,20.289,0,0,1-9.289,2.159"
            transform="translate(-6454.665 3175.799)"
            fill={fill}
          />
        </g>
        <g id="g188" transform="translate(4605.864 497.85)">
          <path
            id="path190"
            d="M6534.121-3133.886a5.922,5.922,0,0,0,1.989,4.65,7.733,7.733,0,0,0,5.28,1.77,9.021,9.021,0,0,0,6.886-2.671,9.816,9.816,0,0,0,2.551-7.106v-2.821c-1.352-.122-2.5-.2-3.469-.24-.943-.041-1.861-.058-2.729-.058q-5.279,0-7.881,1.586A5.307,5.307,0,0,0,6534.121-3133.886Zm17.114,8.878v-5.757a10.617,10.617,0,0,1-4.081,4.65,11.712,11.712,0,0,1-6.376,1.707,11.251,11.251,0,0,1-7.729-2.548,8.584,8.584,0,0,1-2.881-6.808,7.839,7.839,0,0,1,3.6-6.991c2.4-1.579,5.969-2.37,10.688-2.37.943,0,1.861.023,2.78.061q1.339.061,3.6.3v-2.7a8.925,8.925,0,0,0-1.989-6.178,7.022,7.022,0,0,0-5.51-2.219,8.082,8.082,0,0,0-6.019,2.25,8.331,8.331,0,0,0-2.3,6.086h-3.8a12.467,12.467,0,0,1,1.607-6.147,10.834,10.834,0,0,1,4.209-4.081,12.776,12.776,0,0,1,6.223-1.469c3.673,0,6.5,1.02,8.52,3.061s3.01,4.9,3.01,8.577v20.576h-3.546"
            transform="translate(-6530.168 3157.222)"
            fill={fill}
          />
        </g>
        <g id="g192" transform="translate(4641.219 497.965)">
          <path
            id="path194"
            d="M6590.163-3141.858v16.916h-4.134v-31.613h3.851v7.2a13.59,13.59,0,0,1,4.826-5.578,12.532,12.532,0,0,1,7.033-2.1v4.382a12.453,12.453,0,0,0-5.878,1.137,9.864,9.864,0,0,0-4.158,3.722,10.964,10.964,0,0,0-1.54,5.94"
            transform="translate(-6586.029 3157.038)"
            fill={fill}
          />
        </g>
        <g id="g196" transform="translate(4661.721 497.848)">
          <path
            id="path198"
            d="M6632.937-3124.41a13.975,13.975,0,0,1-7.632-2.07,13.526,13.526,0,0,1-5.071-5.849,19.93,19.93,0,0,1-1.811-8.76,17.819,17.819,0,0,1,1.862-8.248,14.9,14.9,0,0,1,5.071-5.759,13.054,13.054,0,0,1,7.348-2.129,13.753,13.753,0,0,1,6,1.288,12.99,12.99,0,0,1,4.554,3.63,15.932,15.932,0,0,1,2.768,5.49,20.285,20.285,0,0,1,.622,6.991h-26v-2.882h21.681q-.233-5.34-2.794-8.307a8.639,8.639,0,0,0-6.882-2.97,8.911,8.911,0,0,0-5.278,1.591,10.689,10.689,0,0,0-3.6,4.468,16.727,16.727,0,0,0-1.294,6.838,18.649,18.649,0,0,0,1.242,7.11,10.176,10.176,0,0,0,3.57,4.649,9.583,9.583,0,0,0,5.588,1.62,9.8,9.8,0,0,0,6.468-2.129,9.965,9.965,0,0,0,3.338-5.85h4.062a13.778,13.778,0,0,1-4.734,8.248,13.877,13.877,0,0,1-9.081,3.032"
            transform="translate(-6618.423 3157.225)"
            fill={fill}
          />
        </g>
        <g id="g200" transform="translate(4338.371 567.439)">
          <path
            id="path202"
            d="M6111.479-3023.932a5.9,5.9,0,0,0,2,4.651,7.694,7.694,0,0,0,5.274,1.771,9.088,9.088,0,0,0,6.9-2.673,9.864,9.864,0,0,0,2.529-7.1v-2.821q-2-.182-3.437-.241c-.978-.041-1.885-.06-2.744-.06-3.508,0-6.134.53-7.9,1.589A5.335,5.335,0,0,0,6111.479-3023.932Zm17.135,8.88v-5.759a10.681,10.681,0,0,1-4.081,4.651,11.729,11.729,0,0,1-6.371,1.707,11.2,11.2,0,0,1-7.733-2.549,8.62,8.62,0,0,1-2.911-6.809,7.861,7.861,0,0,1,3.6-6.988c2.41-1.58,5.966-2.37,10.691-2.37.954,0,1.861.022,2.769.059s2.124.141,3.6.3v-2.7a8.885,8.885,0,0,0-1.98-6.181,6.992,6.992,0,0,0-5.513-2.217,8.066,8.066,0,0,0-6.014,2.248,8.444,8.444,0,0,0-2.315,6.088h-3.771a12.37,12.37,0,0,1,1.6-6.147,10.57,10.57,0,0,1,4.224-4.081,12.781,12.781,0,0,1,6.229-1.468c3.651,0,6.491,1.019,8.5,3.06s3.007,4.9,3.007,8.577v20.577h-3.532"
            transform="translate(-6107.518 3047.266)"
            fill={fill}
          />
        </g>
        <g id="g204" transform="translate(4373.762 567.439)">
          <path
            id="path206"
            d="M6167.554-3033.468v18.417h-4.119v-31.614h3.83v5.936a12.985,12.985,0,0,1,4.214-4.708,10.476,10.476,0,0,1,6.045-1.83,10.276,10.276,0,0,1,7.779,3.061c1.951,2.04,2.914,4.918,2.914,8.639v20.517H6184.1v-19.079c0-3.037-.674-5.378-2-7.018a6.987,6.987,0,0,0-5.8-2.458,8.063,8.063,0,0,0-4.432,1.291,9.053,9.053,0,0,0-3.155,3.567,11.541,11.541,0,0,0-1.155,5.282"
            transform="translate(-6163.435 3047.268)"
            fill={fill}
          />
        </g>
        <g id="g208" transform="translate(4406.986 556.58)">
          <path
            id="path210"
            d="M6220.138-3037.187c0,4.04.9,7.176,2.771,9.417a9,9,0,0,0,7.317,3.36,9.12,9.12,0,0,0,5.324-1.561,9.951,9.951,0,0,0,3.55-4.468,17.007,17.007,0,0,0,1.288-6.868,15.95,15.95,0,0,0-1.337-6.78,10.379,10.379,0,0,0-3.6-4.317,9.241,9.241,0,0,0-5.227-1.5,9.008,9.008,0,0,0-7.317,3.36C6221.038-3044.3,6220.138-3041.188,6220.138-3037.187Zm-4.206,0a20.447,20.447,0,0,1,1.678-8.548,13.546,13.546,0,0,1,4.765-5.762,12.657,12.657,0,0,1,7.244-2.069,11.085,11.085,0,0,1,6.32,1.889,12.151,12.151,0,0,1,4.376,5.31v-18.058h4.133v43.073h-3.841v-6.656a13.029,13.029,0,0,1-4.521,5.339,11.316,11.316,0,0,1-6.467,1.918,12.655,12.655,0,0,1-7.244-2.069,13.525,13.525,0,0,1-4.765-5.788,20.686,20.686,0,0,1-1.678-8.579"
            transform="translate(-6215.933 3064.424)"
            fill={fill}
          />
        </g>
        <g id="g212" transform="translate(4459.558 555.975)">
          <path
            id="path214"
            d="M6330.4-3033.644a11.617,11.617,0,0,1-1.775,6.269,12.471,12.471,0,0,1-5.2,4.56,18.671,18.671,0,0,1-8.381,1.708,18.991,18.991,0,0,1-8.48-1.738,13.033,13.033,0,0,1-5.423-4.832,14.847,14.847,0,0,1-2.144-7.168h4.486a10.89,10.89,0,0,0,3.18,7.319c1.9,1.842,4.659,2.761,8.332,2.761a14.4,14.4,0,0,0,5.448-.991,8.829,8.829,0,0,0,3.87-2.879,7.052,7.052,0,0,0,1.43-4.407,6.243,6.243,0,0,0-2.021-5.071,14.928,14.928,0,0,0-6.483-2.67l-5.373-1.079a24.893,24.893,0,0,1-4.167-1.2,14.36,14.36,0,0,1-3.7-2.071,9.5,9.5,0,0,1-2.662-3.239,10.364,10.364,0,0,1-.986-4.767,10.94,10.94,0,0,1,1.824-6.241,12.858,12.858,0,0,1,5.078-4.378,16.579,16.579,0,0,1,7.494-1.622,16.266,16.266,0,0,1,7.494,1.681,14,14,0,0,1,5.374,4.681,13.106,13.106,0,0,1,2.243,7.136h-4.536a10.317,10.317,0,0,0-1.5-5.1,9.942,9.942,0,0,0-3.673-3.478,10.773,10.773,0,0,0-5.35-1.262,11.363,11.363,0,0,0-5.447,1.2,8.736,8.736,0,0,0-3.377,3.062,7.6,7.6,0,0,0-1.134,4.077,6.064,6.064,0,0,0,1.257,4.021,8.346,8.346,0,0,0,3.3,2.31,25.8,25.8,0,0,0,4.412,1.289l5.373,1.139a18.6,18.6,0,0,1,8.283,3.631c1.947,1.659,2.934,4.107,2.934,7.348"
            transform="translate(-6298.997 3065.381)"
            fill={fill}
          />
        </g>
        <g id="g216" transform="translate(4499.044 567.436)">
          <path
            id="path218"
            d="M6365.338-3023.937a5.971,5.971,0,0,0,2.013,4.649,7.655,7.655,0,0,0,5.269,1.772,9.083,9.083,0,0,0,6.909-2.674,9.884,9.884,0,0,0,2.534-7.1v-2.82c-1.342-.122-2.485-.2-3.454-.241s-1.889-.06-2.759-.06c-3.5,0-6.113.532-7.878,1.59A5.345,5.345,0,0,0,6365.338-3023.937Zm17.147,8.879v-5.758a10.92,10.92,0,0,1-4.076,4.649,11.786,11.786,0,0,1-6.387,1.707,11.216,11.216,0,0,1-7.728-2.549,8.6,8.6,0,0,1-2.908-6.807,7.868,7.868,0,0,1,3.6-6.99c2.41-1.581,5.964-2.368,10.686-2.368.944,0,1.864.02,2.783.06.895.04,2.112.142,3.6.3v-2.7a8.976,8.976,0,0,0-1.988-6.181,7.006,7.006,0,0,0-5.517-2.219,8.121,8.121,0,0,0-6.015,2.249,8.507,8.507,0,0,0-2.311,6.088h-3.777a12.471,12.471,0,0,1,1.591-6.148,11.013,11.013,0,0,1,4.224-4.08,12.813,12.813,0,0,1,6.238-1.469q5.48,0,8.5,3.059c2.013,2.038,3.007,4.9,3.007,8.576v20.579h-3.528"
            transform="translate(-6361.387 3047.272)"
            fill={fill}
          />
        </g>
        <g id="g220" transform="translate(4529.634 556.098)">
          <path
            id="path222"
            d="M6421.177-3021.637h-4.127v-28.076h-7.329v-3.54h7.329v-2.879c0-2.917.75-5.158,2.227-6.719a8.351,8.351,0,0,1,6.328-2.339,10.421,10.421,0,0,1,1.977.178,9.516,9.516,0,0,1,1.676.48v3.482a4.929,4.929,0,0,0-1.4-.39,12.493,12.493,0,0,0-1.551-.092,5.257,5.257,0,0,0-3.8,1.293,5.555,5.555,0,0,0-1.326,4.107v2.879h9.155v3.54h-9.155v28.076"
            transform="translate(-6409.721 3065.189)"
            fill={fill}
          />
        </g>
        <g id="g224" transform="translate(4553.896 567.441)">
          <path
            id="path226"
            d="M6462.576-3014.45a13.987,13.987,0,0,1-7.638-2.07,13.746,13.746,0,0,1-5.093-5.849,20.38,20.38,0,0,1-1.79-8.761,17.588,17.588,0,0,1,1.866-8.249,14.872,14.872,0,0,1,5.067-5.756,12.989,12.989,0,0,1,7.362-2.13,13.659,13.659,0,0,1,5.975,1.288,12.885,12.885,0,0,1,4.563,3.63,16.261,16.261,0,0,1,2.773,5.488,19.578,19.578,0,0,1,.605,6.989H6450.3v-2.879h21.657q-.227-5.339-2.8-8.307a8.64,8.64,0,0,0-6.882-2.973,8.978,8.978,0,0,0-5.269,1.591,10.589,10.589,0,0,0-3.58,4.47,16.647,16.647,0,0,0-1.312,6.837,18.606,18.606,0,0,0,1.26,7.112,10.123,10.123,0,0,0,3.555,4.646,9.586,9.586,0,0,0,5.6,1.621,9.768,9.768,0,0,0,6.454-2.13,9.851,9.851,0,0,0,3.328-5.849h4.084a13.9,13.9,0,0,1-4.74,8.249,13.872,13.872,0,0,1-9.076,3.03"
            transform="translate(-6448.055 3047.264)"
            fill={fill}
          />
        </g>
        <g id="g228" transform="translate(4586.068 558.68)">
          <path
            id="path230"
            d="M6519.79-3024.152v3.718a8.909,8.909,0,0,1-2.232.72,13.2,13.2,0,0,1-2.258.18,10.815,10.815,0,0,1-4.591-.958,7.178,7.178,0,0,1-3.272-3.062,10.928,10.928,0,0,1-1.217-5.519v-19.137h-7.331v-3.538h7.331v-9.359h4.134v9.359h9.74v3.538h-9.74v18.239c0,2.478.533,4.2,1.624,5.157a6.029,6.029,0,0,0,4.16,1.44,8.823,8.823,0,0,0,1.852-.18,8.749,8.749,0,0,0,1.8-.6"
            transform="translate(-6498.89 3061.107)"
            fill={fill}
          />
        </g>
        <g id="g232" transform="translate(4611.414 568.035)">
          <path
            id="path234"
            d="M6567.106-3046.325l-15.951,43.192h-4.422l4.549-12-12.346-31.2h4.422l10.046,26.157,9.51-26.157h4.192"
            transform="translate(-6538.937 3046.325)"
            fill={fill}
          />
        </g>
        <g id="g236" transform="translate(4642.125 592.332)">
          <path
            id="path238"
            d="M6594.892-3004.1a3.839,3.839,0,0,1-1.025,2.7,3.555,3.555,0,0,1-2.689,1.081,3.575,3.575,0,0,1-2.665-1.081,3.75,3.75,0,0,1-1.051-2.7,3.824,3.824,0,0,1,1.051-2.757,3.585,3.585,0,0,1,2.665-1.081,3.564,3.564,0,0,1,2.689,1.081,3.916,3.916,0,0,1,1.025,2.757"
            transform="translate(-6587.461 3007.936)"
            fill={fill}
          />
        </g>
        <g id="g240" transform="translate(4188.37 484.668)">
          <path
            id="path242"
            d="M5963.931-3098.355a33.584,33.584,0,0,1-5.515,19.016,36.8,36.8,0,0,1-15.633,12.943q-10.094,4.652-24.465,4.655-14.021,0-24.442-4.811a39.13,39.13,0,0,1-16.427-13.653,42.115,42.115,0,0,1-6.94-20.673h23.671c.327,6.311,2.5,11.361,6.565,15.149,4.02,3.788,9.745,5.683,17.105,5.683a32.1,32.1,0,0,0,11.053-1.736,16.958,16.958,0,0,0,7.5-5.129,12.564,12.564,0,0,0,2.757-8.127,10.706,10.706,0,0,0-4.042-8.681c-2.665-2.21-6.542-3.839-11.59-4.893l-18.3-3.47a54.192,54.192,0,0,1-11.917-3.708,36.192,36.192,0,0,1-9.627-6.234,26.349,26.349,0,0,1-6.543-9.155,30.018,30.018,0,0,1-2.383-12.308,30.606,30.606,0,0,1,5.444-17.911,36.743,36.743,0,0,1,14.932-12.233,50.879,50.879,0,0,1,21.755-4.419,52.536,52.536,0,0,1,22.572,4.657,39.866,39.866,0,0,1,16.03,13.256,38.225,38.225,0,0,1,6.706,20.437h-23.67a21.69,21.69,0,0,0-3.155-10.653,19.986,19.986,0,0,0-7.5-7.021,22.353,22.353,0,0,0-10.819-2.526,22.522,22.522,0,0,0-10,2.052,15.783,15.783,0,0,0-6.4,5.365,13.1,13.1,0,0,0-2.219,7.419,9.7,9.7,0,0,0,2.219,6.627,16.178,16.178,0,0,0,5.772,4.1,34.881,34.881,0,0,0,7.641,2.367l19.255,3.788q14.826,3,22.736,10.177,7.887,7.185,7.875,19.649"
            transform="translate(-5870.509 3178.051)"
            fill={fill}
          />
        </g>
        <g id="g244" transform="translate(4066.527 483.797)">
          <path
            id="path246"
            d="M5764.31-3101.41a35.054,35.054,0,0,1-3.16,5.629,32.207,32.207,0,0,1-11.116,9.943,30.863,30.863,0,0,1-14.912,3.628,31.831,31.831,0,0,1-13.663-2.842,30.671,30.671,0,0,1-10.411-7.891,35.655,35.655,0,0,1-6.707-12.151,48.28,48.28,0,0,1-2.364-15.465,47.577,47.577,0,0,1,2.364-15.388,35.851,35.851,0,0,1,6.707-12.073,29.869,29.869,0,0,1,10.411-7.811,32.643,32.643,0,0,1,13.663-2.762,31.444,31.444,0,0,1,14.912,3.551,32.162,32.162,0,0,1,11.207,9.864,36.84,36.84,0,0,1,2.864,4.97h25.6a52.626,52.626,0,0,0-7.729-15.938,54.558,54.558,0,0,0-20.118-17.122,59.253,59.253,0,0,0-27.052-6.156,55.028,55.028,0,0,0-22.187,4.5,56.354,56.354,0,0,0-18.141,12.546,58.307,58.307,0,0,0-12.162,18.7,60.823,60.823,0,0,0-4.319,23.122,61.41,61.41,0,0,0,4.319,23.2,59.01,59.01,0,0,0,12.162,18.859,55.776,55.776,0,0,0,18.141,12.623,55.019,55.019,0,0,0,22.187,4.5,58.9,58.9,0,0,0,26.983-6.154,55.055,55.055,0,0,0,20.051-17.122,54.578,54.578,0,0,0,8.024-16.754H5764.31"
            transform="translate(-5677.993 3179.424)"
            fill={fill}
          />
        </g>
        <g id="g248" transform="translate(3762.58 486.088)">
          <path
            id="path250"
            d="M5256.942-3165.057l35.8,10.224V-3175.8h-95v20.989h35.819v92.321h23.361l.02-102.562"
            transform="translate(-5197.742 3175.805)"
            fill={fill}
          />
        </g>
        <g id="g252" transform="translate(4025.365 486.172)">
          <path
            id="path254"
            d="M5636.318-3134.388v-41.286h-23.363v34.613l23.363,6.673"
            transform="translate(-5612.956 3175.674)"
            fill={fill}
          />
        </g>
        <g id="g256" transform="translate(3952.139 486.172)">
          <path
            id="path258"
            d="M5570.48-3130.856h-49.863v-44.818h-23.363v113.31h23.363V-3110.5h49.863v48.135h23.363v-68.492H5570.48"
            transform="translate(-5497.254 3175.674)"
            fill={fill}
          />
        </g>
        <g id="g260" transform="translate(3857.646 513.02)">
          <path
            id="path262"
            d="M5393.885-3105.692v-27.562h-18.373v27.562h-27.559v18.375h4.583l32.163-9.189,32.163,9.189h4.583v-18.375h-27.559"
            transform="translate(-5347.953 3133.253)"
            fill={fill}
          />
        </g>
        <g id="g264" transform="translate(3885.214 563.543)">
          <path
            id="path266"
            d="M5400.707-3053.424l-9.2,2.625v20.344h18.374V-3050.8l-9.176-2.625"
            transform="translate(-5391.509 3053.424)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

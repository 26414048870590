import { Reducer } from "redux";
import { OpinionState, OpinionTypes } from "./types";

export const INITIAL_STATE: OpinionState = {
  data: {
    name: '',
    birthdate: '',
    external_attendance_id: '',
    sector: '',
    rows: [],
    opinions: [],
    userSpecialities: [],
    now: ''
  },
  list: {
    data: [],
    limit: "10",
    page: "1",
    total: 0,
  },
  specialities: [],
  priorities: [],
  error: false,
  loading: false,
  success: false,
  createdSuccess: false,
};

const reducer: Reducer<OpinionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OpinionTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case OpinionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: INITIAL_STATE.data,
        list: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinionTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinionTypes.LOAD_OPINION_REQUEST:
      return { ...state, loading: true, success: false, createdSuccess: false };
    case OpinionTypes.LOAD_OPINION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinionTypes.LOAD_OPINION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinionTypes.LOAD_SELECT_SPECIALITY_REQUEST:
      return { ...state, loading: true, success: false };
    case OpinionTypes.LOAD_SELECT_SPECIALITY_SUCCESS:
      return {
        ...state,
        specialities: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinionTypes.LOAD_SELECT_SPECIALITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinionTypes.LOAD_SELECT_PRIORITY_REQUEST:
      return { ...state, loading: true, success: false };
    case OpinionTypes.LOAD_SELECT_PRIORITY_SUCCESS:
      return {
        ...state,
        priorities: action.payload.data,
        loading: false,
        success: true,
        error: false,
      };
    case OpinionTypes.LOAD_SELECT_PRIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case OpinionTypes.CREATE_OPINION_REQUEST:
      return { ...state, loading: true, success: false, createdSuccess: false };
    case OpinionTypes.CREATE_OPINION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        createdSuccess: true
      };
    case OpinionTypes.CREATE_OPINION_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        createdSuccess: false
      };
    case OpinionTypes.CLEAN:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;

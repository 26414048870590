import React, {
  useState,
  ReactNode,
  DetailedHTMLProps,
  FormHTMLAttributes,
} from "react";
import { Theme } from "@mui/material/styles";

import { Badge, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from "@mui/material";

import {
  TabBody,
  TabBodyItem,
  TabContent,
  TabNav,
  TabNavItem,
  TabNavItem_1,
  TabNavItemDetails,
  TabNavItemAlingRigth,
  TabNavItemAlingLeft,
  WrapperName,
  TabNavItemPermission,
  TabNavPermission,
  WrapperHeaderForm,
  ButtonStyle,
  FormGroupSection,
} from "./styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ClientFormHeader from "../Inputs/Forms/ClientName";
import _ from "lodash";
import CepForm from "../Inputs/Forms/CepForm";
import ResponsibleForm from "../Inputs/Forms/ResponsibleForm";
import ToggleActive from "../Button/ToggleActive";
import TabList from "../List/TabList";
import IntegrationForm from "../Inputs/Forms/IntegrationForm";
import PermissionList from "../List/PermissionList";
import UserContactForm from "../Inputs/Forms/UserContactForm";
import UserProfessionForm from "../Inputs/Forms/UserProfessionForm";
import UserForm from "../Inputs/Forms/UserForm";
import UserCompanyForm from "../Inputs/Forms/UserCompanyForm";
import CheckListForm from "../Inputs/Forms/CheckListForm";
import CompanyForm from "../Inputs/Forms/CompanyForm";
import PatientForm from "../Inputs/Forms/patientForm";
import ChangePasswordConfiguration from "../Inputs/Forms/ChangePasswordTab";
import ClientLogs from "../List/ClientLogs";
import CompanyIcon from "../Icons/Company";
import UserIcon from "../Icons/User";
import { ReactComponent as EmailIcon } from "../../assets/img/Icon-email.svg";
import { ReactComponent as PrintIcon } from "../../assets/img/Icon-imprimir.svg";
import PatientIcon from "../Icons/Patient";
import { ReactComponent as MaleIcon } from "../../assets/img/icon-male-1.svg";
import { ReactComponent as FemaleIcon } from "../../assets/img/ionic-md-female.svg";
import { ReactComponent as ProfileIcon } from "../../assets/img/icon-user-1.svg";
import { ReactComponent as PortalActiveIcon } from "../../assets/img/icon-portal-active.svg";
import { ReactComponent as PortalDesactiveIcon } from "../../assets/img/icon-portal-desactive.svg";
import ComputerIcon from "../Icons/Computer";
import CellphoneIcon from "../Icons/Cellphone";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomerState } from "../../store/ducks/customers/types";
import { UserState } from "../../store/ducks/users/types";
import SESSIONSTORAGE from "../../helpers/constants/sessionStorage";
import { checkEditPermission } from "../../utils/permissions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { IButtons } from "../Button/ButtonTabs";
import { ThemeProvider } from "@mui/material/styles";

import theme from "../../theme/theme";

interface IPropsPermissionFrom {
  state: {
    active: boolean;
    name: string;
    rights: never[];
    mode: string;
    _id: string;
    customer_id: string;
  };
  setState: React.Dispatch<
    React.SetStateAction<{
      active: boolean;
      name: string;
      rights: never[];
      mode: string;
      _id: string;
      customer_id: string;
    }>
  >;
  customerState: CustomerState;
  userState: UserState;
  params: IPageParams;
  canEditPermission: boolean;
  buttonsPermission: IButtons[];
  modePermission: string;
  setModePermission: React.Dispatch<React.SetStateAction<string>>;
  cleanSelectProfession: () => void;
}

interface ITabprops {
  navItems: INavItems[];
  children?: ReactNode;
  state?: any;
  setState?: Function;
  setValidations?: Function;
  fieldsValidation?: any;
  canEdit?: boolean;
  getAddress?: any;
  cepStatus?: any;
  user?: string;
  customerState?: any;
  tableCells?: any;
  mode?: string;
  initialTab: number;
  setInitialTab?: any;
  params: IPageParams;
  rowsPortal?: any;
  rowsApp?: any;
  propsPermissionForm?: IPropsPermissionFrom;
  autoCompleteSetting?: any;
  modePermission?: string;
  objectSubmitRef?: React.RefObject<HTMLFormElement>;
}

interface IPageParams {
  id?: string;
  mode?: string;
}

interface right {
  active: boolean;
  label: string;
  right: string;
}

interface permission {
  active: boolean;
  label: string;
  rights: right[]
}

interface INavItems {
  name: string;
  components: any;
  badge?: number;
  icon?: any;
  permissions?: permission[]
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabBody
      style={{ padding: "16px" }}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <TabBodyItem>{children}</TabBodyItem>
    </TabBody>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const TabForm = (props: ITabprops) => {
  const {
    navItems,
    state,
    setState,
    setValidations,
    fieldsValidation,
    canEdit,
    cepStatus,
    getAddress,
    user,
    customerState,
    mode,
    initialTab,
    setInitialTab,
    params,
    rowsPortal,
    rowsApp,
    propsPermissionForm,
    autoCompleteSetting,
    modePermission,
    objectSubmitRef,
  } = props;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setInitialTab && setInitialTab(newValue);
  };
  const [value, setValue] = useState(initialTab);
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );
  function handleComponents(component: string, index: number) {
    switch (component) {
      case "ClientFormHeader":
        return (
          <ClientFormHeader
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            fieldsValidation={fieldsValidation}
            canEdit={canEdit ? canEdit : false}
            params={params}
          />
        );
      case "CepForm":
        return (
          <CepForm
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            cepStatus={cepStatus}
            getAddress={getAddress}
            params={params}
          />
        );
      case "CompanyForm":
        return (
          <CompanyForm
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            cepStatus={cepStatus}
            getAddress={getAddress}
            params={params}
          />
        );
      case "PatientForm":
        return (
          <PatientForm
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            cepStatus={cepStatus}
            getAddress={getAddress}
            params={params}
            detailsPatientIs={true}
          />
        );
      case "UserForm":
        return (
          <UserForm
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            params={params}
          />
        );
      case "UserCompanyForm":
        return (
          <UserCompanyForm
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            params={params}
          />
        );
      case "UserContactForm":
        return (
          <UserContactForm
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            params={params}
          />
        );
      case "UserProfessionForm":
        return (
          <UserProfessionForm
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            canEdit={canEdit ? canEdit : false}
            params={params}
          />
        );
      case "ResponsibleForm":
        return (
          <ResponsibleForm
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            setValidations={setValidations ? setValidations : () => false}
            fieldsValidation={fieldsValidation}
            canEdit={canEdit ? canEdit : false}
            user={user}
            params={params}
          />
        );
      case "PermissionList":
        return (
          <PermissionList
            customerState={customerState}
            mode={mode ? mode : ""}
            propsPermissionForm={propsPermissionForm}
          />
        );
      case "CheckListFormPortal":
        return (
          <CheckListForm
            state={state}
            setState={setState}
            rows={rowsPortal}
            mode={mode}
            checkList={"portal"}
            autoCompleteSetting={autoCompleteSetting}
          />
        );
      case "CheckListFormApp":
        return (
          <CheckListForm
            state={state}
            setState={setState}
            rows={rowsApp}
            mode={mode}
            checkList={"app"}
            autoCompleteSetting={autoCompleteSetting}
          />
        );
      case "ToggleActive":
        return (
          <ToggleActive
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            canEdit={canEdit ? canEdit : false}
          />
        );
      case "IntegrationForm":
        return (
          <IntegrationForm
            index={index}
            state={state}
            setState={setState ? setState : () => false}
            canEdit={canEdit ? canEdit : false}
            objectSubmitRef={objectSubmitRef}
          />
        );
      case "ChangePassword":
        return <ChangePasswordConfiguration state={state} />;
      case "ClientLogs":
        return <ClientLogs state={state} customerState={customerState} />;
      default:
        return <TabBodyItem>Not found!</TabBodyItem>;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
          style={{
            flexGrow: 1,
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px ",
              marginLeft: "15px",
              width: "100%",
            }}
          >
            {/* Select ou nome da função do prestador */}
            {mode === "create" ? (
              <Autocomplete
                sx={{
                  display: "inline-block",
                  "& input": {},
                  width: "20rem",
                }}
                id="combo-box-profession"
                options={autoCompleteSetting?.handleProfessionList()}
                getOptionLabel={(option:any) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione a função"
                    variant="outlined"
                    style={{ fontSize: "12px", height: "32px" }}
                  />
                )}
                value={autoCompleteSetting?.selectProfession()}
                onChange={(event, value) => {
                  if (value) {
                    autoCompleteSetting?.handleSelectProfession(value);
                  }
                }}
                size="small"
                fullWidth
              />
            ) : (
              <Grid
                item
                style={{
                  fontSize: "16px",
                  color: "var(--black)",
                  fontWeight: "bold",
                }}
              >
                <Typography fontWeight={500}>{state.name}</Typography>
              </Grid>
            )}
            {/* Menu select */}
            <TabContent
              position="static"
              style={{
                background: "none",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "256px",
              }}
            >
              <TabNavPermission
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={{
                  fontSize: "13px",
                  background: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                {navItems.map(({ name, icon }: INavItems, index: number) => (
                  <TabNavItemPermission
                    sx={{ display: "flex" }}
                    key={index}
                    value={index}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          minHeight: "32px",
                          cursor: "pointer",
                          "& svg, path, rect": { cursor: "pointer" },
                        }}
                      >
                        {value === index ? icon.active : icon.default}

                        <Typography variant="body2" sx={{ cursor: "pointer" }}>
                          {name}
                        </Typography>
                      </Box>
                    }
                    wrapped
                    className={value === index ? "active" : "desactive"}
                    {...a11yProps({ index })}
                  ></TabNavItemPermission>
                ))}
              </TabNavPermission>
            </TabContent>            
          </Box>
          {/* Direitos */}
          {navItems.map(
            ({ name, components, badge, permissions }: INavItems, index: number) => {
              const headers: Set<string> = new Set()
              permissions?.map(permission => permission?.rights?.map(right => headers.add(right?.label)))
              const headersArray: string[] = Array.from(headers)
              return (
                <TabPanel value={value} index={index} key={index}>
                  <FormGroupSection>
                  <FormControl
                        component="fieldset"
                        style={{
                          marginBottom: "4px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                       <FormLabel sx={{ width: "200px" }}>
                        <Typography
                          variant="body1"
                          color={theme.palette.text.primary}
                        />
                      </FormLabel> 
                      <FormGroup
                        aria-label="position"
                        row
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <WrapperHeaderForm>
                      {headersArray?.map((label: string) => (
                        <Typography
                        sx={{width: '65px', textAlign: 'center'}}
                        variant="body1"
                        fontSize={"0.79rem"}
                        color={theme.palette.text.secondary}
                      >
                        {label}
                      </Typography>
                      ))}
                    </WrapperHeaderForm>
                      </FormGroup>
                      </FormControl>
                  {permissions?.map(({label, active, rights}) => {
                    return (
                      <FormControl
                        component="fieldset"
                        style={{
                          marginBottom: "4px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                       <FormLabel sx={{ width: "200px" }}>
                        <Typography
                          variant="body1"
                          color={theme.palette.text.primary}
                        >
                          {label}
                        </Typography>
                      </FormLabel> 
                      <FormGroup
                        aria-label="position"
                        row
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        {headersArray.map((val: any, index: number) => {
                          const right = _.find(rights, {label: val})
                          const checked = _.indexOf(state.rights, right?.right) > -1
                          return (
                            <FormControlLabel
                            style={{
                              display: "flex",
                              margin: "0 2px",
                            }}
                            value={right?.right}
                            control={
                              <Checkbox
                                sx={{
                                  width: "80px",
                                  height: "35px",
                                  backgroundColor: "rgba(243, 243, 243, 0.35)",
                                  borderRadius: "0",
                                  color: theme.palette.text.disabled,
                                  "&.Mui-checked": { color: theme.palette.success.main },
                                }}
                                checked={!!right?.active && checked}
                                icon={
                                  !!right?.active ? <CheckCircleOutlineOutlinedIcon /> :
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color={theme.palette.text.disabled}
                                      fontSize={"2rem"}
                                      sx={{ position: "absolute", bottom: "-20px  " }}
                                    >
                                      -
                                    </Typography>
                                  </Box>
                                }
                                checkedIcon={
                                  !!right?.active ? <CheckCircleOutlineOutlinedIcon/> : <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color={theme.palette.text.disabled}
                                      fontSize={"2rem"}
                                      sx={{ position: "absolute", bottom: "-20px  " }}
                                    >
                                      -
                                    </Typography>
                                  </Box>
                                }
                              />
                            }
                            label={""}
                            labelPlacement="end"
                            disabled={(!(!!right)) || (mode === "view")}
                            onChange={(event, value) => {
                              const rights = [...state.rights];

                              if (value) {
                                setState((prevState: any) => ({
                                  ...prevState,
                                  rights: [...prevState.rights, right?.right],
                                }));
                              } else {
                                _.pull(rights, right?.right);
                                setState((prevState: any) => ({
                                  ...prevState,
                                  rights: rights,
                                }));
                              }
                            }}
                          />
                          )
                        }
                        )}
                      </FormGroup>
                      </FormControl>
                    )
                  })}
                  </FormGroupSection>
                </TabPanel>
              );
            }
          )}
      </div>
    </ThemeProvider>
  );
};
export default React.memo(TabForm);

import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import ButtonTabs, { IButtons } from "../../../../components/Button/ButtonTabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import Header from "../../../../components/Header/Overview";
import ScrollCard from "../../../../components/Card/ScrollCard";
import CardInfo from "../../../../components/Card/Info";
import SESSIONSTORAGE from "../../../../helpers/constants/sessionStorage";
import Table from "../../../../components/Table";
import SimpleHeader from "../../../../components/Header/SimpleHeader";
import theme from "../../../../theme/theme";
import CreateMedicalOpinion from "../create";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import {
  loadOpinionRequest,
} from "../../../../store/ducks/opinions/actions";
import Loading from "../../../../components/Loading";
import { opinions } from "../../../../store/ducks/opinions/types";
import RequestOpinionIcon from "@mui/icons-material/ContentPasteGo";
import AnswerOpinion from "../../answerOpinion/create";
import LOCALSTORAGE from "../../../../helpers/constants/localStorage";
import { loadRequestTelemedicineUrl } from "../../../../store/ducks/telemedicine/actions";

interface IPageParams {
  id?: string;
  type?: 'opinionS' | 'opinion';
}

export default function MedicalOpinionOverview(props: IPageParams) {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [selectCard, setSelectCard] = useState<opinions>({
    _id: '',
    active:true,
    company_id: '',
    createdAt: '',
    patient_name: '',
    birthdate: '',
    external_attendance_id: '',
    external_requester_id: '',
    external_sector_name: '',
    external_specialty_id: '',
    external_specialty_name: '',
    priority_id: [],
    reason: '',
    requester_id : {
      _id: '',
      name: ''
    },
    started_at: '',
    updatedAt: '',
    status: {
      color: '#777777',
      label: ''
    },
    opinionanswers: []
  });
  const [activeCard, setActiveCard] = useState(false);
  const [cardType, setCardType] = useState("");
  const [detailingModal, setDetailingModal] = useState(false);
  const [createOpinion, setCreateOpinion] = useState(false);
  const [opinionRequest, setOpinionRequest] = useState([])
  const dispatch = useDispatch();
  const opinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  const [answerOpinion, setAnswerOpinion] = useState(false);

  useEffect(() => {
    dispatch(loadOpinionRequest({id: params.id}))
  }, [opinionState.createdSuccess])

  const buttons: IButtons[] = [
    /* {
      name: "Nova Solicitação",
      onClick: () => {
        setCreateOpinion(true);
      },
      variant: "contained",
      background: "primary",
      show: !detailingModal && !createOpinion,
    }, */
    {
      name: "Voltar",
      onClick: () => {
        if (
          location.pathname === `/opinion/${params.id}/overview` &&
          detailingModal == false &&
          createOpinion == false &&
          answerOpinion == false
        ) {
          navigate("/opinion");
        } else if (
          location.pathname === `/opinionS/${params.id}/overview` &&
          detailingModal == false &&
          createOpinion == false &&
          answerOpinion == false
        ) {
          navigate("/opinionS");
        } else {
          setDetailingModal(false);
          setCreateOpinion(false);
          setAnswerOpinion(false);
        }
      },
      variant: "contained",
      background: "primary",
      show: true,
    },
  ];

  const personalCard = {
    card: "Dados Pessoais",
    info: ["Dados Pessoais"],
  };
  const planCard = {
    card: "Plano e Internação",
    info: ["Dados de atendimento", "Dados do Plano"],
  };

  const gridPropsPlan = {
    lg: 6,
    xl: 6,
    sx: 6,
    md: 6,
  };

  const telemedicineState = useSelector((state: ApplicationState) => state.telemedicine);
  const customer_id = localStorage.getItem(LOCALSTORAGE.CUSTOMER);

  const content = {
    tittle: "HeaderOverview",
    rows: opinionState.data.rows,
    qrCodeState: [],
    careState: [],
    setCreateOpinion: () => setCreateOpinion(true),
    onClickTelemedicine: () => {
      dispatch(loadRequestTelemedicineUrl({
        external_attendance_id: opinionState.data.external_attendance_id,
        customer_id: customer_id,
      }))
      // window.open(telemedicineState?.data?.link)
    },
  };

  const medicalOpinionFilter = opinionState.data.opinions
  const cards = ["Parecer"];

  const opinionAnswerState = useSelector(
    (state: ApplicationState) => state.opinionAnswer
  );

  useEffect(() => {
    if (opinionAnswerState.createdSuccess && opinionState.success) {
      const ansewrFind = opinionState.data.opinions.find((opinion) => opinion._id === selectCard._id)
      if (ansewrFind) {
        setSelectCard(ansewrFind)
      }
    }
  }, [opinionAnswerState.createdSuccess, opinionState.success])

  useEffect(() => {
    setOpinionRequest([
      {
        name: "Paciente",
        value: opinionState.data.name,
      },
      {
        name: "Prestador Solicitante",
        value: selectCard?.requester_id?.name,
      },
      {
        name: "Setor",
        value: selectCard?.external_sector_name,
      },
      {
        name: "Data da Solicitação",
        value: selectCard?.started_at,
      },
      {
        name: "Especialista Requisitado",
        value: selectCard?.external_specialty_name,
      },
      {
        name: "Prioridade",
        value: selectCard?.priority_id[0]?.name,
      },
      {
        name: "Justificativa",
        value: selectCard?.reason,
      },
      {
        name: "Situação",
        value: selectCard?.status?.label,
      },
    ])
  }, [selectCard])

  const createIconComponent =
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontWeight: "bold",
      color: theme.palette.common.black,
      fontSize: "12px",
      textAlign: "center",
      width: "2.5rem",
      height: "2.5rem",
      borderRadius: "8px",
      backgroundColor: theme.palette.common.white,
      cursor: "pointer",
      "& svg": {
        cursor: "pointer",
        " & path": {
          cursor: "pointer",
        },
      }
    }}
  >
    <Tooltip
      title={'NOVA SOLICITAÇÃOs'}
    >
      <RequestOpinionIcon
        sx={{
          color: theme.palette.terciaryDark.main
        }}
        onClick={() => setCreateOpinion(true)}
      />
    </Tooltip>
  </Box>

  const opinionsTabbleCells: any = [
    { name: "Médico Solicitante", align: "left" },
    { name: "Para Especialidade", align: "left" },
    { name: "Data da Solicitação", align: "center" },
    { name: "Status do Parecer", align: "center" },
    { name: "", align: "center", component: props.type === 'opinionS' && createIconComponent }
  ]

  return (
    <Sidebar>
      <Container sx={{ padding: "20px" }}>
        {opinionState.loading && <Loading />}
        <Typography variant="h5" fontWeight={700} mb={5} color="primary">
          {`${props.type === 'opinionS' ? 'SOLICITAÇÃO' : 'RESPOSTA'} DO PARECER`}
        </Typography>
        <Grid container sx={{ background: "rgb(245, 245, 245)" }}>
          <Header content={content} opinion={props.type} />
          {createOpinion ? (
            <CreateMedicalOpinion close={() => setCreateOpinion(false)} />
          ) : answerOpinion ? (
            <AnswerOpinion close={() => setAnswerOpinion(false)} detailsAnswer={opinionRequest} detailObj={selectCard}/>
          ) : !detailingModal ? (
            <>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingY: 1,
                  width: "calc(100% - 68px)",
                  margin: "0 auto",
                }}
              >
                <CardInfo
                  content={content}
                  tittle={personalCard}
                  alergicIs={true}
                  gridProps={gridPropsPlan}
                  integration={integration}
                  opinion={true}
                />

                <CardInfo
                  content={content}
                  tittle={planCard}
                  alergicIs={false}
                  gridProps={gridPropsPlan}
                  integration={integration}
                  opinion={true}
                />
              </Grid>
              <Container sx={{ width: "calc(100% - 35px)", paddingBottom: 6 }}>
                <Table
                  tableCells={opinionsTabbleCells}
                  requestOpinionFilter={medicalOpinionFilter}
                  setDetailingModal={setDetailingModal}
                  setSelectCard={setSelectCard}
                  setAnswerOpinion={setAnswerOpinion}
                  opinionType={props.type}
                >
                  {""}
                </Table>
              </Container>
            </>
          ) : (
            <>
            <Container
              sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingY: 5 }}
            >
              <SimpleHeader title="Solicitação de Parecer" />
              <Box
                sx={{
                  backgroundColor: theme.palette.whiteBackgroud.main,
                  color: theme.palette.text.primary,
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "space-between",
                  textAlign: "center",
                  paddingX: 3,
                  paddingY: 2,
                  minHeight: "48px",
                }}
              >
                {opinionRequest.map((item) => (
                  <Box display={"flex"} gap={1}>
                    <Typography fontWeight={600}>{item.name}: </Typography>
                    <Typography>{item.value}</Typography>
                  </Box>
                ))}
              </Box>
            </Container>
            {
          selectCard?.opinionanswers?.map((answers: any, index) => {
          return (
            <Container
              sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingBottom: 5 }}
            >
              <SimpleHeader title="Resposta do Parecer" canceled={answers?.canceled_by} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.whiteBackgroud.main,
                  color: theme.palette.text.primary,
                  paddingX: 3,
                  paddingY: 3,
                  minHeight: "48px",
                }}
              >
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Resonsável:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answered_by}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Especialidade do Resonsável:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answered_by_speciality}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Data:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answered_at}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Resposta:</Typography>
                      <Typography sx={{textDecoration: answers?.canceled_by ? `line-through`: ``}}>{answers.answer}</Typography>
                    </Box>
                  </Box>
              </Box>
            </Container>
          )
        })
      }
            </>
          )}
        </Grid>
        <ButtonTabs buttons={buttons} canEdit={false} />
      </Container>
    </Sidebar>
  );
}

import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function ComputerIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : "18"}
      height={height ? height : "13"}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_329_1161)">
        <path
          d="M15 11.249C15.3964 11.2469 15.7759 11.0879 16.0555 10.8068C16.3351 10.5258 16.492 10.1454 16.492 9.749L16.5 1.5C16.5 1.10218 16.342 0.720644 16.0607 0.43934C15.7794 0.158035 15.3978 0 15 0H3C2.60218 0 2.22064 0.158035 1.93934 0.43934C1.65804 0.720644 1.5 1.10218 1.5 1.5V9.749C1.5 10.1468 1.65804 10.5284 1.93934 10.8097C2.22064 11.091 2.60218 11.249 3 11.249H0C0 11.6468 0.158035 12.0284 0.43934 12.3097C0.720644 12.591 1.10218 12.749 1.5 12.749H16.5C16.8978 12.749 17.2794 12.591 17.5607 12.3097C17.842 12.0284 18 11.6468 18 11.249H15ZM3 1.5H15V9.749H3V1.5ZM9 12C8.85166 12 8.70666 11.956 8.58332 11.8736C8.45999 11.7912 8.36386 11.6741 8.30709 11.537C8.25033 11.4 8.23547 11.2492 8.26441 11.1037C8.29335 10.9582 8.36478 10.8246 8.46967 10.7197C8.57456 10.6148 8.7082 10.5434 8.85368 10.5144C8.99917 10.4855 9.14997 10.5003 9.28701 10.5571C9.42406 10.6139 9.54119 10.71 9.6236 10.8333C9.70601 10.9567 9.75 11.1017 9.75 11.25C9.74947 11.4488 9.67029 11.6392 9.52975 11.7797C9.38921 11.9203 9.19875 11.9995 9 12Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_329_1161">
          <rect width="17.999" height="12.749" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

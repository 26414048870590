import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

function SvgComponent(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      viewBox={`0 0 30.034 20.251`}
      width={width ? width : 30.034}
      height={height ? height : 20.251}
    >
      <path
        d="M27.314,20.729a.557.557,0,1,1,1.113,0,4.9,4.9,0,0,1-4.9,4.9.557.557,0,1,1,0-1.113,3.789,3.789,0,0,0,3.785-3.785ZM10.916,6.741a3.789,3.789,0,0,0-3.785,3.785.557.557,0,0,1-1.113,0,4.9,4.9,0,0,1,4.9-4.9.557.557,0,1,1,0,1.113ZM8.691,14.652A5.839,5.839,0,0,1,10.362,11a5.163,5.163,0,0,1,7.174-.271.307.307,0,0,0,.413,0A5.162,5.162,0,0,1,25.122,11a5.853,5.853,0,0,1,.587,7.53,19.261,19.261,0,0,1-7.47,5.682,1.419,1.419,0,0,1-1-.006,20.01,20.01,0,0,1-7.464-5.677A5.865,5.865,0,0,1,8.691,14.652Zm1.987,3.224a18.955,18.955,0,0,0,6.959,5.287.306.306,0,0,0,.216,0,18.24,18.24,0,0,0,6.952-5.288,4.719,4.719,0,0,0-.474-6.1,4.2,4.2,0,0,0-2.979-1.261A3.9,3.9,0,0,0,18.7,11.546a1.428,1.428,0,0,1-1.918,0,4.053,4.053,0,0,0-5.63.234,4.719,4.719,0,0,0-.474,6.1Zm12.294,4.969a.557.557,0,0,1,.557-.557,1.56,1.56,0,0,0,1.559-1.559.557.557,0,1,1,1.113,0A2.675,2.675,0,0,1,23.529,23.4.557.557,0,0,1,22.972,22.845ZM10.916,8.968a1.56,1.56,0,0,0-1.559,1.559.557.557,0,1,1-1.113,0,2.675,2.675,0,0,1,2.672-2.672.557.557,0,1,1,0,1.113Z"
        transform="translate(-3.017 -5.628)"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;

import React from "react";
import { Container, ContainerProps, Typography } from "@mui/material";
import theme from "../../../theme/theme";
import styled from "styled-components";

interface ISimpleHeader {
  title: string;
  canceled?: boolean;
  icon?: React.ReactNode;
}

export const ContainerHeader = styled(Container)<ContainerProps>(
  () => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "none",
    gap: "14px",
    borderRadius: "8px 8px 0 0",
    paddingBottom: "8px",
    paddingTop: "8px",
  })
);

export default function SimpleHeader({ title, icon, canceled }: ISimpleHeader) {
  return (
    <ContainerHeader sx={{backgroundColor: canceled ? theme.palette.error.main : theme.palette.primary.main}}>
      <Typography
        className="name"
        fontSize={14}
        color={theme.palette.common.white}
        fontWeight={500}
      >
        {title}
      </Typography>
    </ContainerHeader>
  );
}

import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
  displayColor?: any;
}
export default function CellphoneIcon(props: IPropsSvg) {
  const { fill, width, height, displayColor } = props;

  return (
    <svg
      width={width ? width : "11"}
      height={height ? height : "18"}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_329_1160)">
        <path
          d="M1.57099 -0.000976562H9.42499C9.85996 0.016707 10.2701 0.206299 10.5654 0.526165C10.8608 0.846031 11.017 1.27002 11 1.70502V15.355C11.0171 15.7894 10.8613 16.2128 10.5669 16.5325C10.2724 16.8523 9.86326 17.0423 9.42899 17.061H1.57099C1.13672 17.0423 0.727556 16.8523 0.433107 16.5325C0.138658 16.2128 -0.0170833 15.7894 -1.29425e-05 15.355V1.70502C-0.0170833 1.27068 0.138658 0.84728 0.433107 0.527529C0.727556 0.207777 1.13672 0.0177365 1.57099 -0.000976562Z"
          fill={fill}
        />
        <path
          d="M2.37803 1.762H8.59703C8.75909 1.76069 8.91981 1.7913 9.07003 1.8521C9.22025 1.9129 9.35703 2.00269 9.47254 2.11635C9.58806 2.23001 9.68006 2.36531 9.74328 2.51453C9.80651 2.66374 9.83973 2.82395 9.84103 2.986V12.777C9.83973 12.9391 9.80651 13.0993 9.74328 13.2485C9.68006 13.3977 9.58806 13.533 9.47254 13.6467C9.35703 13.7603 9.22025 13.8501 9.07003 13.9109C8.91981 13.9717 8.75909 14.0023 8.59703 14.001H2.37803C2.2159 14.0023 2.05509 13.9717 1.90481 13.9108C1.75452 13.85 1.6177 13.7601 1.50217 13.6463C1.38664 13.5325 1.29466 13.3971 1.2315 13.2478C1.16833 13.0985 1.13521 12.9381 1.13403 12.776V2.986C1.13534 2.82395 1.16856 2.66374 1.23178 2.51453C1.29501 2.36531 1.38701 2.23001 1.50252 2.11635C1.61804 2.00269 1.75481 1.9129 1.90503 1.8521C2.05525 1.7913 2.21598 1.76069 2.37803 1.762Z"
          fill={displayColor}
        />
        <path
          d="M5.271 16.268C5.67803 16.268 6.008 15.938 6.008 15.531C6.008 15.124 5.67803 14.794 5.271 14.794C4.86396 14.794 4.534 15.124 4.534 15.531C4.534 15.938 4.86396 16.268 5.271 16.268Z"
          fill={displayColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_329_1160">
          <rect width="10.996" height="17.063" fill={displayColor} />
        </clipPath>
      </defs>
    </svg>
  );
}

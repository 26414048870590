import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function IconMeasurement(props: IPropsSvg) {
  const { fill, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "50.784"}
      height={height ? height : "50"}
      viewBox="0 0 50.784 50"
    >
      <defs></defs>
      <path
        fill={fill}
        d="M11.582,38.052V35.638c-.98,0-1.782-.8-1.782-2.673v-2.94c-.3-.062-.6-.134-.891-.214A11.656,11.656,0,0,1,0,18.781v-.962H.891V5.346A4.443,4.443,0,0,1,4.6.971,9.09,9.09,0,0,1,5.586.909,1.777,1.777,0,1,1,7.128,3.564,1.764,1.764,0,0,1,5.6,2.691a6.9,6.9,0,0,0-.953.089A2.666,2.666,0,0,0,2.673,5.346V17.819h.891v.989c0,3.483,3.3,6.441,6.637,7.5a9.032,9.032,0,0,0,2.717.419h.044a8.773,8.773,0,0,0,2.673-.419,7.981,7.981,0,0,0,5.747-7.5v-.989h.891L22.229,5.337A2.66,2.66,0,0,0,20.3,2.788a7.373,7.373,0,0,0-.953-.089A1.789,1.789,0,1,1,19.36.909a9.062,9.062,0,0,1,.989.062,4.448,4.448,0,0,1,3.662,4.366l.044,12.482h.891v.962a11.655,11.655,0,0,1-8.909,11.03c-.294.08-.589.152-.891.214v2.94c0,1.871-.8,2.673-1.782,2.673v2.414c0,5.6,4.2,10.166,9.355,10.166s9.355-4.562,9.355-10.166V20.492h.053l-.053-.009a7.89,7.89,0,0,1,7.573-7.859,7.858,7.858,0,0,1,7.574,8.1v3.421h0a4.455,4.455,0,1,1-1.782,0V20.723a6.08,6.08,0,0,0-5.792-6.317A5.532,5.532,0,0,0,35.629,16.2a6.616,6.616,0,0,0-1.773,4.339V38.052c0,6.584-5,11.948-11.138,11.948S11.582,44.636,11.582,38.052ZM43.656,28.51a2.673,2.673,0,1,0,2.673-2.673A2.673,2.673,0,0,0,43.656,28.51Zm1.782,0a.891.891,0,1,1,.891.891A.891.891,0,0,1,45.438,28.51Z"
      />
    </svg>
  );
}

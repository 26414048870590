/**
 * Action types
 */
export enum OpinionTypes {
  LOAD_REQUEST = "@opinions/LOAD_REQUEST",
  LOAD_SUCCESS = "@opinions/LOAD_SUCCESS",
  LOAD_FAILURE = "@opinions/LOAD_FAILURE",

  LOAD_OPINION_REQUEST = "@opinions/LOAD_OPINION_REQUEST",
  LOAD_OPINION_SUCCESS = "@opinions/LOAD_OPINION_SUCCESS",
  LOAD_OPINION_FAILURE = "@opinions/LOAD_OPINION_FAILURE",

  LOAD_SELECT_SPECIALITY_REQUEST = "@opinions/LOAD_SELECT_SPECIALITY_REQUEST",
  LOAD_SELECT_SPECIALITY_SUCCESS = "@opinions/LOAD_SELECT_SPECIALITY_SUCCESS",
  LOAD_SELECT_SPECIALITY_FAILURE = "@opinions/LOAD_SELECT_SPECIALITY_FAILURE",

  LOAD_SELECT_PRIORITY_REQUEST = "@opinions/LOAD_SELECT_PRIORITY_REQUEST",
  LOAD_SELECT_PRIORITY_SUCCESS = "@opinions/LOAD_SELECT_PRIORITY_SUCCESS",
  LOAD_SELECT_PRIORITY_FAILURE = "@opinions/LOAD_SELECT_PRIORITY_FAILURE",

  CREATE_OPINION_REQUEST = "@opinions/CREATE_OPINION_REQUEST",
  CREATE_OPINION_REQUEST_SUCCESS = "@opinions/CREATE_OPINION_REQUEST_SUCCESS",
  CREATE_OPINION_REQUEST_FAILURE = "@opinions/CREATE_OPINION_REQUEST_FAILURE",

  CLEAN = "@opinions/CLEAN",
}

/**
 * Data types
 */
interface rows {
  name: string;
  value: string | { name: string }
}

export interface opinions {
  _id: string;
  active: boolean;
  company_id: string;
  createdAt: string;
  patient_name: string;
  birthdate: string;
  external_attendance_id: string;
  external_requester_id: string;
  external_sector_name: string;
  external_specialty_id: string;
  external_specialty_name: string;
  priority_id: {
        active: boolean;
        created_at: string;
        name: string;
        updated_at: string;
        value: number;
        _id: string;
      }[];
  reason: string;
  requester_id: {
    _id: string;
    name: string;
  };
  started_at: string;
  updatedAt: string;
  status: {
    color: '#777777',
    label: ''
  },
  opinionanswers: {
    active: boolean;
    answered_at: string;
    createdAt: string;
    canceled_at: string;
    answer: string;
  }[];
}
export interface OpinionInterface {
  now: string;
  name: string;
  birthdate: string;
  external_attendance_id: string;
  sector: string;
  rows: rows[];
  opinions: opinions[];
  userSpecialities: string[];
}

export interface OpinionInterfaceList {
  id: number;
  patient_name: string;
  birthdate: string;
  status: string;
  assistant_doctor?: string;
}

export interface OpinionList {
  data: OpinionInterfaceList[];
  limit: string;
  page: string;
  total: number;
  search?: string;
  status?: string;
}

export interface Especialities {
  id: string;
  name: string;
}

export interface Priorities {
  _id: string;
  name: string;
}

/**
 * State type
 */
export interface OpinionState {
  data: OpinionInterface;
  list: OpinionList;
  specialities: Array<string>;
  priorities: Priorities[];
  loading: boolean;
  error: boolean;
  success: boolean;
  createdSuccess: boolean;
}

export interface CreateOpinion {
  patient_name: string;
  birthdate?: string;
  external_attendance_id: string;
  external_specialty_id: string;
  external_specialty_name: string;
  external_sector_name: string;
  requester_id: string;
  external_requester_id: string;
  reason: string;
  sector: string;
  priority_id: string;
}

/**
 * State type
 */
export type LoadRequestParams = Partial<Omit<OpinionList, "data">>;

export default {
  TOKEN: "@thcs_token",
  TOKEN_AUX: "@thcs_token_aux",
  TOGGLE_SIDEBAR: "@thcs_toggle_s_fixed",
  EXPIRED_SESSION: "@thcs_expired_session",
  REMEMBER_ME: "@thcs_remember_me",
  USERNAME: "@thcs_username",
  USER_ID: "@thcs_user_id",
  COMPANY_SELECTED: `@thcs_company_selected`,
  COMPANY_NAME: `@thcs_company_name`,
  CUSTOMER: `@thcs_customer`,
  CUSTOMER_NAME: `@thcs_customer_name`,
  INTEGRATION_COMPANY_SELECTED: `@thcs_integration_company_selected`,
  DATE_GENERATION_QR_CODE: `@thcs_date_generation_qr_code`,
  SOLLAR_INTEGRATION_USER_ID: `@thcs_integration_user_id`,
  STATUS_ATTACHMENTS_INTEGRATION: `@thcs_status_attachments_integration`,
};

import React, { ChangeEvent, useEffect, useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import { checkViewPermission } from "../../../../utils/permissions";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../../store";
import { Container } from "@mui/material";
import TabTittle from "../../../../components/Text/TabTittle";
import NoPermission from "../../../../components/Erros/NoPermission";
import SESSIONSTORAGE from "../../../../helpers/constants/sessionStorage";
import LOCALSTORAGE from "../../../../helpers/constants/localStorage";
import SearchComponent from "../../../../components/List/Search";
import PaginationComponent from "../../../../components/Pagination";
import {
  loadRequest,
} from "../../../../store/ducks/opinions/actions";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import Loading from "../../../../components/Loading";

export default function RequestOpinion() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const currentCompany = localStorage.getItem(LOCALSTORAGE.COMPANY_SELECTED);
  const [search, setSearch] = useState("");
  const [valueStatus, setValueStatus] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const opinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  useEffect(() => {
      dispatch(loadRequest());
  }, [currentCompany]);

  const handleSearchInput = (event: any) => {
    dispatch(loadRequest({ search: event}));
  };

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(event.target.value);

    if (event.target.value === "") {
      handleSearchInput("");
    }
  };

  const handleKeyEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchInput(search);
    }
  };

  const handleClickSearch = (e: any) => {
    handleSearchInput(search);
  };

  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  return (
    <Sidebar>
      {checkViewPermission(
        "opinion",
        JSON.stringify(rightsOfLayoutState)
      ) ? (
        <Container>
          {opinionState.loading && <Loading />}

          <TabTittle tittle="Lista de Pareceres" />
          {integration ? (
            <>
              <SearchComponent
                onChangeInput={handleChangeInput}
                handleButton={() => navigate("/opinionS")}
                inputPlaceholder="Pesquise por nome, nº de atendimento, CPF, etc..."
                value={search}
                onKeyEnter={handleKeyEnter}
                onClickSearch={handleClickSearch}
              />
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Médico Assistente", align: "left" },
                  { name: "Status do Parecer", align: "center" },
                ]}
                requestOpinionState={opinionState?.list?.data}
              >
              </Table>
            </>
          ) : (
            <>
              <SearchComponent
                onChangeInput={handleChangeInput}
                handleButton={() => navigate("/opinionS")}
                inputPlaceholder="Pesquise por nome, nº de atendimento, CPF, etc..."
                value={search}
                onKeyEnter={handleKeyEnter}
                onClickSearch={handleClickSearch}
              />
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Médico Assistente", align: "left" },
                  { name: "Status do Parecer", align: "center" },
                ]}
                requestOpinionState={opinionState?.data}
              >
              </Table>
            </>
          )}
          <PaginationComponent
              page={opinionState.list.page}
              rowsPerPage={opinionState.list.limit}
              totalRows={opinionState.list.total}
              handleFirstPage={() =>
                dispatch(
                  loadRequest({
                    page: "1",
                    limit: opinionState.list.limit,
                    search,
                  })
                )
              }
              handleLastPage={() =>
                dispatch(
                  loadRequest({
                    page: Math.ceil(
                      +opinionState.list.total / +opinionState.list.limit
                    ).toString(),
                    limit: opinionState.list.limit,
                    search,
                  })
                )
              }
              handleNextPage={() =>
                dispatch(
                  loadRequest({
                    page: (+opinionState.list.page + 1).toString(),
                    limit: opinionState.list.limit,
                    search,
                  })
                )
              }
              handlePreviosPage={() =>
                dispatch(
                  loadRequest({
                    page: (+opinionState.list.page - 1).toString(),
                    limit: opinionState.list.limit,
                    search,
                  })
                )
              }
              handleChangeRowsPerPage={(event) => {
                dispatch(
                  loadRequest({
                    limit: event.target.value,
                    page: "1",
                    search,
                  })
                );
              }}
            />
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
